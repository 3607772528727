.mg {
    margin: 0px 30px;
}
.ta-left {
    text-align: left;
}
.client {
    line-height: 80px;
    border-bottom: solid 1px #e3e7f1;
   .clientHeader {
        .label {
            color: #1B242F;
            font-weight: bold;
            font-size: 24px;
        }
        .dowloadIcon {
            display: flex;
            font-size: 20px;
        }
        .buttonAdd {
            color: white;
        }
        .addIcon {
            font-size: 14px;
            margin-right: 7px;
        }
    }
}
.main-table {
    text-align: left;
    color: #ABABAB;
    .table-header{
        line-height: 56px;
        border-bottom:solid 3px #e3e7f1;
        .label-header {
            color: #444141;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .table-body {
        line-height: 66px;
        border-bottom:solid 1px #e3e7f1;
        .client-infor {
            .name {
                font-size: 14px;
                color: #1B242F;
                font-weight: bold;
            }
            margin-left: 10px;
            p {
                line-height: 25px;
            }
        }
        .table-tag {
            span {
                margin-right: 5px;
            }
        }
        .tag-blue {
            background-color: #E2F0FD;
            color: #184cdb;
        }
        .tag-pink {
            background-color: #FAEDFD;
            color: #e056ff;
        }
    }
}
