.pagination {
    .page-item:first-child {
        .page-link {
            background-color: #e3e3e3;
        }
    }

    .page-item:last-child {
        .page-link {
            background-color: #e3e3e3;
        }
    }

    .page-link {
        margin: 0 10px;
        color: #4b4b4b;
        border: 1px solid #e3e3e3;
        background-color: #fff;
        border-radius: 5px;
        line-height: 24px;
        font-size: 14px;
        padding: 4px 13px;

        color: #4b4b4b;
        font-weight: 700;
    }

    @media screen and (max-width: 767px) {
        .page-link {
            margin: 0 10px;
            font-size: 10px;
            padding: 4px 10px;
            height: 30px;
            line-height: 20px;
        }
    }
}
